import Vue from 'vue';
import Rails from '@rails/ujs';
import '../admin/public/bootstrap';
import LoadScript from '@common/components/LoadScript.vue';
import Navigation from '../../javascript/application/navigation';
import OverlayTrigger from '../application/javascript/Overlays/OverlayTrigger.vue';
import GlossaryTrigger from '../application/javascript/Glossary/GlossaryTrigger.vue';
import SideMenuItem from '../application/javascript/SideMenuItem.vue';
import PortalVue from 'portal-vue';
import editorLinks from '../application/javascript/editorLinks';

import AOS from 'aos';
import 'aos/dist/aos.css';

require('../styles/application.scss');

// Add all images from the "images" folder to the pipeline
require.context('../application/images', true);

Rails.start();
require.context('../application/images', true);

// Import all images <%= image_pack_tag 'rails.png' %>
require.context('../application/images', true);

Vue.use(PortalVue);

Vue.component('load-script', LoadScript);
Vue.component('navigation', Navigation);
Vue.component('overlay-trigger', OverlayTrigger);
Vue.component('glossary-trigger', GlossaryTrigger);
Vue.component('side-menu-item', SideMenuItem);
Vue.component('photo-gallery', () => import('../application/javascript/PhotoGallery.vue'));
Vue.component('has-state', () => import('../application/javascript/hasState.vue'));
Vue.component('collapsible', () => import('../application/javascript/Collapsible.vue'));
// Old flourish chart component - remove when data transfer is done
Vue.component('flourish', () => import('../application/javascript/Flourish/FlourishChart.vue'));
Vue.component('chart', () => import('../application/javascript/Flourish/FlourishChart.vue'));
Vue.component('sticky-header', () => import('../application/javascript/StickyHeader.vue'));
Vue.component('email-subscription', () =>
  import('../application/javascript/EmailSubscription.vue')
);
Vue.component('partners-slider', () => import('../application/javascript/PartnersSlider.vue'));
Vue.component('testimonials-slider', () =>
  import('../application/javascript/TestimonialsSlider.vue')
);
Vue.component('featured-stories-slider', () =>
  import('../application/javascript/FeaturedStoriesSlider.vue')
);
Vue.component('business-modelling-page', () =>
  import('../application/javascript/business-modelling/components/Page.vue')
);
Vue.component('resources-browser', () => import('../application/javascript/resources/Browser'));

// Search page
import '../../../app/views/search/vue/index';

// Bootstrap the Vue root instance
window.addEventListener('DOMContentLoaded', function() {
  // Sharing selected text in social media
  Sharect.config({
    facebook: false,
    twitter: false,
    backgroundColor: '#26605F'
  });

  Sharect.appendCustomShareButtons([
    {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M23.9921 12.0004C23.9921 18.0612 19.5008 23.0717 13.6663 23.8848C13.1208 23.9605 12.5626 24 11.996 24C11.3421 24 10.6999 23.9479 10.0745 23.847C4.36266 22.9271 0 17.9729 0 12.0004C0 5.37294 5.37136 0 11.9969 0C18.6224 0 23.9938 5.37294 23.9938 12.0004H23.9921Z" fill="none"/><path d="M4.8665 5.29297L10.3993 12.6923L4.83203 18.7085H6.08535L10.9599 13.4415L14.8981 18.7085H19.1624L13.3186 10.8929L18.5008 5.29297H17.2475L12.7588 10.1438L9.13165 5.29297H4.86733H4.8665ZM6.70906 6.21621H8.66764L17.3181 17.7853H15.3596L6.70906 6.21621Z" fill="white"/></svg>',
      url: 'https://twitter.com/intent/tweet?url=PAGE_URL&text=TEXT_SELECTION'
    },
    {
      icon:
        '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/></svg>',
      url: 'https://www.linkedin.com/shareArticle?mini=true&url=PAGE_URL&title=TEXT_SELECTION'
    }
  ]).init();

  new Vue({
    el: '#js-wrapper',
    mounted() {
      AOS.init();

      setTimeout(() => {
        editorLinks();
      }, 1000);
    }
  });
});
